<template>
	<div>
		<div class="home">
			<div id="businesses" class="home__container">
				<div style="margin-bottom:5vh; margin-top:5vh;">
					<!-- <h1>Quality, <em>dependable,</em> on-demand workers with <em>accountable,</em> on-site support</h1> -->
					<h1>Understaffed?  We can help!</h1>
					<p class="page-intro">See the j<em>u</em>mp difference</p>
				
					<router-link :to="{name: 'quote'}">
						<button class="btn btn__primary btn__mega mt-5">Get a Quote</button>
					</router-link>
				</div>
			</div>
			<Jumpers />
			<div class="whyHire" id="checklist">
		<h2>Why Hire Us</h2>
		<div class="checklist__wrapper">
			<div class="checklist--item">
				<div class="i-border">
					<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="300"></i>
				</div>
				<p class="new"><span class="strong">50,000+ Workers</span> - We have over 50,000 workers nationwide, with the capacity to handle just about any request in any city.</p>
				</div>
				<div class="checklist--item">
					<div class="i-border">
						<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="600"></i>
					</div>
					<p class="new"><span class="strong">Dedicated Service</span> - Our customers enjoy account managers committed to their success. We are here to support you 100%.</p>
				</div>
				<div class="checklist--item">
					<div class="i-border">
						<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="900"></i>
					</div>
					<p class="new"><span class="strong">On-Site Support</span> - We are your on-site partners. We make sure your order is full, your workers are ready to work, and we deal with any on-site issues that come up.</p>
				</div>
				<div class="checklist--item">
					<div class="i-border">
						<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="1200"></i>
					</div>
					<p class="new"><span class="strong">Shift Management</span> - We create your shifts, manage applications, and handle last-minute changes. We don't expect you to create and staff your own events!</p>
				</div>
				<div class="checklist--item">
					<div class="i-border">
						<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="1500"></i>
					</div>
					<p class="new"><span class="strong">Industry Experience</span> - We have over a decade of industry experience and know how to handle complex orders and situations. </p>
				</div>
				<!-- <div class="checklist--item">
					<div class="i-border">
						<i class="fas fa-check fa-2x" data-aos="zoom-in" data-aos-anchor="#checklist" data-aos-delay="1800"></i>
					</div>
					<p class="new"><span class="strong">Financial Stability</span> - Backed by investors, we have the capital and resources to ensure happy workers and strong relationships for years to come.</p>
				</div>
			</div> -->
		</div>
	</div>
			<VenueTypes />
			<div class="faq__wrapper">
				<h2>Business FAQs</h2>
				<div class="faq">
					<div class="question">
						<h4>Are jump professionals employees or independent contractors?</h4>
						<i class="fa-light fa-plus fa-2x" @click="showEmp = !showEmp" style="cursor:pointer;"></i>
					</div>
					<div class="answer" v-if="showEmp">Our professionals are primarily independent contractors who bring their own specialized skills to the jump platform. We provide W2 employees when required.</div>
				</div>
				<div class="faq">
					<div class="question">
						<h4>How does pricing work?</h4>
						<i class="fa-light fa-plus fa-2x" @click="showPricing = !showPricing" style="cursor:pointer;"></i>
					</div>
					<div class="answer" v-if="showPricing">Rather than flat percentages, pricing is calculated on a per client/shift/position/event basis.</div>
				</div>
				<!-- <div class="faq">
					<div class="question">
						<h4>How does insurance work?</h4>
						<i class="fa-light fa-plus fa-2x" @click="showInsurance = !showInsurance" style="cursor:pointer;"></i>
					</div>
					<div class="answer" v-if="showInsurance">wgrwg</div>
				</div> -->
				<!-- <div class="faq">
					<div class="question">
						<h4>How does you screen workers?</h4>
						<i class="fa-light fa-plus fa-2x" @click="showScreen = !showScreen" style="cursor:pointer;"></i>
					</div>
					<div class="answer" v-if="showScreen">W</div>
				</div> -->
				<div class="faq">
					<div class="question">
						<h4>How do you manage staff on-site?</h4>
						<i class="fa-light fa-plus fa-2x" @click="showManage = !showManage" style="cursor:pointer;"></i>
					</div>
					<div class="answer" v-if="showManage">FWe provide a dedicated shift lead or on-site manager to our Enterprise Clients to make sure their events run smoothly. We don't just send staff, we are on-site partners.</div>
				</div>
				<div class="faq">
					<div class="question">
						<h4>How are you different from other staffing apps?</h4>
						<i class="fa-light fa-plus fa-2x" @click="showDifferent = !showDifferent" style="cursor:pointer;"></i>
					</div>
					<div class="answer" v-if="showDifferent">Businesses rely on multiple gig platforms to fill their staffing needs. J<em>u</em>mp stands out for three reasons: our experience, our quality of staff, and our Enterprise service.</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'
import Jumpers from '@/components/Jumpers.vue'
import WorkerBenefits from '@/components/WorkerBenefits.vue'
import VenueTypes from '@/components/VenueTypes.vue'

export default {
	name: 'businesses',
	data() {
    return {
    	showPricing: false,
    	showInsurance: false,
    	showScreen: false,
    	showManage: false,
    	showDifferent: false,
    	showEmp: false,
    }
  },
	components: {
		Jumpers,
		WorkerBenefits,
		VenueTypes
	}
}
</script>